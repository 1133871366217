<template>
  <div class="card">
    <h4 class="card-title">Visualizar categoría</h4>
    <template v-if="categoria">
      <div class="cols2">
        <pd-input
          class="my-0"
          readonly
          classInput="form-control readonly text-bold text-success"
          v-model="categoria.descripcion"
          label="Descripción"
          id="descripcion"
          placeholder="descripcion"
        ></pd-input>
        <pd-input
          class="my-0"
          readonly
          classInput="form-control readonly text-bold text-secondary"
          v-model="categoria.abreviatura"
          label="Abreviatura"
          id="abreviatura"
          placeholder="abreviatura"
        ></pd-input>
        <pd-input
          class="my-0"
          readonly
          classInput="form-control readonly text-bold text-secondary"
          v-model="categoria.coeficiente"
          label="Coeficiente"
          id="coeficiente"
          placeholder="coeficiente"
        ></pd-input>
        <pd-input
          class="my-0"
          readonly
          classInput="form-control readonly text-bold text-secondary"
          v-model="convenio"
          label="¿Convenio colectivo?"
          id="convenio"
          placeholder="convenio"
        ></pd-input>
        <pd-input
          class="my-0"
          readonly
          classInput="form-control readonly text-bold text-secondary"
          v-model="categoria.basico"
          label="Básico de convenio"
          id="basico"
          placeholder="basico"
        ></pd-input>
        <pd-input
          class="my-0"
          readonly
          classInput="form-control readonly text-bold text-secondary"
          v-model="categoria.total"
          label="Sueldo básico"
          id="total"
          placeholder="total"
        ></pd-input>
      </div>
      <div class="d-flex space-between">
        <button
          class="btn btn-info"
          @click="$router.push({ name: 'ListarCategorias' })"
        >
          Volver
        </button>
        <div>
          <button class="btn btn-warning" @click="openEdit = true">
            Editar categoria
          </button>
          <button class="btn btn-error" @click="eliminarCategoria">
            Eliminar categoria
          </button>
        </div>
      </div>
      <modal v-if="openEdit" @close="openEdit = false">
        <editar-categoria
          slot="modal-body"
          :categoria="categoria"
          @saved="saved"
        ></editar-categoria>
      </modal>
    </template>
  </div>
</template>
<script>
import { CategoriaServices } from "../services/CategoriaServices";
import Swal from "sweetalert2";
import Modal from "@/components/elements/Modal";
import EditarCategoria from "./EditarCategoria.vue";
export default {
  name: "VerCategoria",
  components: {
    Modal,
    EditarCategoria,
  },
  data() {
    return {
      categoria: null,
      openEdit: false,
    };
  },
  computed:{
    convenio(){
      return (this.categoria.convenio) ? 'Si' : 'No'
    } 
  },
  mounted() {
    CategoriaServices.api.find(this.$route.params.id).then((response) => {
      this.categoria = response.categoria;
    });
  },
  methods: {
    eliminarCategoria() {
      Swal.fire({
        title: "Confirme",
        html: `<p>Está seguro que desea eliminar <b>${this.categoria.descripcion}</b>?</p>`,
        icon: "question",
        showConfirmButton: true,
        confirmButtonText: "Eliminar",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.isConfirmed) {
          CategoriaServices.api.delete(this.categoria).then(() => {
            Swal.fire("Éxito", "<p>Se ha eliminado con éxito</p>", "success");
            this.$router.push({ name: "ListarCategorias" });
          });
        }
      });
    },
    saved() {
      this.openEdit = false;
      CategoriaServices.api.find(this.categoria.id).then((response) => {
        this.categoria = response.categoria;
      });
    },
  },
};
</script>